/**
 *
 *  Powered by nhobio Development Team ©
 *
 */


import  PropTypes                                                   from "prop-types";
import  React                                                       from "react";
import  { Button, Container, Grid, Header, Image, Segment }         from "semantic-ui-react";

import  img_appstore                                                from "../assets/imgs/appstore-button-1.0.svg";
import  img_googleplay                                              from "../assets/imgs/gplay-button-1.0.svg";
import  img_welcome                                                 from "../assets/imgs/header-all-large-1.1.svg";


function HomepageHeading({ mobile }) {

    return (
        <Container>
            <Container text>
                <Header
                    as='h1'
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: mobile ? '2.5em' : '5em',
                        fontWeight: 900,
                        marginTop: mobile ? '0.2em' : '0.4em',
                        color: '#1D1031'
                    }}
                >
                    <style>
                        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');
                    </style>

                    Nos expériences.<br/>
                    Nos avenirs.
                </Header>

            </Container>
            <Header
                as='h3'
                style={{
                    fontFamily: 'Nunito',
                    fontSize: mobile ? '1.3em' : '3em',
                    fontWeight: 400,
                    marginTop: mobile ? '1em' : '1.5em',
                    color: '#1D1031'
                }}
            >
                Partageons nos expériences pour bâtir nos avenirs.

            </Header>

            <Image size={'massive'}>
                <object
                    type="image/svg+xml"
                    width="100%" height="100%"
                    data={img_welcome}>
                </object>
            </Image>

            <Container>
                <a href={'https://form.typeform.com/to/sXUmQLq5'}>
                    <Button color={"violet"} inverted positive size={'massive'} style={{ marginTop: '1em', fontWeight: 200,}} >
                        Participer au projet
                    </Button>
                </a>
            </Container>

            <Container >
                <Segment
                    textAlign='center'
                    vertical
                >
                    <Header
                        as='h1'
                        style={{
                            fontFamily: 'Nunito',
                            fontSize: mobile ? '1.8em' : '2.5em',
                            fontWeight: 900,
                            marginTop: mobile ? '1em' : '2em',
                            color: '#1D1031'
                        }}
                    >
                        Bientôt disponible !
                    </Header>

                    <Grid>
                        <Grid.Column width={8}>
                            <a href={'/download'}>
                                <Image centered src={img_appstore} size={'medium'}/>
                            </a>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <a href={'/download'}>
                                <Image centered src={img_googleplay} size={'medium'}/>
                            </a>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Container>
        </Container>
    );
};

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
};

export { HomepageHeading };
