/**
 *
 *  Powered by nhobio Development Team ©
 *
 */


import  Emoji                   from "a11y-react-emoji";
import  autoBind                from "auto-bind";
import  PropTypes               from 'prop-types';
import  { Link }                from "react-router-dom";
import  React, { Component }    from 'react';
import {
    Button,
    Container,
    Header,
    Image,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
    Modal,
} from 'semantic-ui-react';

import  img_logo                from '../assets/imgs/logo-0002.svg';
import  img_logo_header         from '../assets/imgs/logo-header-color-1.0.svg';
import { HomepageHeading }      from "../components/HomepageHeading";



const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};


/**
 * Container for desktop screen size
 */
class DesktopContainer extends Component {

    state = {};

    hideFixedMenu = () => this.setState({ fixed: false });
    showFixedMenu = () => this.setState({ fixed: true });

    render() {
        const { children } = this.props;
        const { fixed } = this.state;

        return (
            <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        textAlign='center'
                        style={{ minHeight: 700, padding: '1em 0em' }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? 'top' : null}
                            pointing={!fixed}
                            secondary={!fixed}
                            size='large'
                            style={{ borderBottom: '0px'}}
                        >
                            <Link style={{ padding: '0.1em 8em', cursor: 'pointer' }} to="/">
                                <Image size={'medium'} style={{ paddingTop: '0.3em' }}>
                                    <object
                                        type="image/svg+xml"
                                        width="100%" height="100%"
                                        data={img_logo_header}>
                                    </object>
                                </Image>
                            </Link>
                        </Menu>
                        <HomepageHeading />
                    </Segment>
                </Visibility>
                {children}
            </Responsive>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
};




/**
 * Container for mobile screen size
 */
class MobileContainer extends Component {
    state = {};

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
        const { children } = this.props;
        const { sidebarOpened } = this.state;

        return (
            <Responsive
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >

                <Image centered size={'small'} style={{ textAlign: 'center', margin: "1em" }}>
                    <object
                        type="image/svg+xml"
                        width="100%" height="100%"
                        data={img_logo_header}>
                    </object>
                </Image>

                <Segment
                    textAlign='center'
                    style={{ minHeight: 700, padding: '1em 0em'/* HERE*/ }}
                    vertical
                >
                    <HomepageHeading mobile />
                </Segment>


                <Sidebar.Pusher dimmed={sidebarOpened}>
                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node,
};




const ResponsiveContainer = ({ children }) => (
    <div >
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </div>
);

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
};




class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscribed: window.location.search.search('subscribed=true') !== -1,
            download: !!props.download,
        };
        autoBind(this);
    }

    async handleClose () {
        window.location = '/';
    }

    render () {
        return (
            <ResponsiveContainer>

                <Modal
                    dimmer={'blurring'}
                    open={this.state.download}
                    onClose={this.handleClose}>
                    <Modal.Header> Arrgh ! </Modal.Header>
                    <Modal.Content image>
                        <Image
                            wrapped
                            size='medium'
                            src={img_logo}
                        />
                        <Modal.Description>
                            <Container textAlign={"left"} style={{ marginTop: '2em'}}>
                                <Header style={{ fontWeight: 200,}}>
                                    L&apos;application nhobio est en cours de développement &nbsp;&nbsp;
                                    <Emoji symbol='🔨' label='travail'/><Emoji symbol='📲' label='app'/>&nbsp;<Emoji symbol='😅' label='suer'/> </Header>
                                <Header> Mais tu peux participer au projet dès maintenant <Emoji symbol='👇' label='direction'/> </Header>
                                <Container textAlign={"center"}>
                                    <a href={'https://form.typeform.com/to/sXUmQLq5'}>
                                        <Button color={"violet"} inverted positive size={'massive'} style={{ marginTop: '1em', fontWeight: 200,}} >
                                        Participer au projet
                                        </Button>
                                    </a>
                                </Container>
                            </Container>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            size={'large'}
                            onClick={this.handleClose}
                        >
                            À bientôt <Emoji symbol='👋' label='bye'/>
                        </Button>
                    </Modal.Actions>
                </Modal>

            </ResponsiveContainer>
        );
    }
}


export default Home;
