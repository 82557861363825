/**
 *
 *  Powered by nhobio Development Team ©
 *
 */


import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import React                   from 'react';

import FirstNhobListening      from "./pages/FirstNhobListening";
import Home                    from "./pages/Home";
import WorkInProgress          from "./pages/WorkInProgress";


function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/home">
                        <Home />
                    </Route>
                    <Route path="/download">
                        <Home download={true} />
                    </Route>
                    <Route path="/nhob/consultantssi">
                        <FirstNhobListening />
                    </Route>
                    <Route path="*">
                        <WorkInProgress />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}


export default App;
