/**
 *
 *  Powered by nhobio Development Team ©
 *
 */


import  Emoji                   from 'a11y-react-emoji';
import  autoBind                from "auto-bind";
import  React, { Component }    from 'react';
import {
    Container, Divider, Header, Image,
} from 'semantic-ui-react';

import  img_logo_header         from "../assets/imgs/logo-header-color-1.0.svg";
import  AudioPlayer             from "../components/AudioPlayer";


class FirstNhobListening extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render () {
        return (
            <Container textAlign={"center"} >
                <Divider hidden />
                <Image size={'medium'} style={{ paddingTop: '0.3em' }}>
                    <object
                        type="image/svg+xml"
                        width="100%" height="100%"
                        data={img_logo_header}>
                    </object>
                </Image>
                <Header>
                    CONSULTANT EN CYBERSECURITE <Emoji symbol='👨🏻‍💻' label='hacker'/>
                </Header>
                <AudioPlayer />
            </Container>
        );
    }
}


export default FirstNhobListening;
