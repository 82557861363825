/**
 *
 *  Powered by Nhobio Development Team ©
 *
 */


import  { Link }                    from "react-router-dom";
import  React, { Component }        from 'react';
import  {
    Container,
    Divider,
    Image, Grid,
}                                   from 'semantic-ui-react';

import  img_logo_header             from '../assets/imgs/logo-header-color-1.0.svg';
import  MailChimp                   from '../components/MailChimp';


class WorkInProgress extends Component {
    render() {
        return (
            <Container
                textAlign={'center'}
                style={{ minHeight: 700, padding: '10em 0em' }}
            >

                <Grid>
                    <Grid.Row centered>
                        <Link style={{ cursor: 'pointer' }} to="/">
                            <Image size={'medium'} style={{ paddingTop: '0.3em' }}>
                                <object
                                    type="image/svg+xml"
                                    width="100%" height="100%"
                                    data={img_logo_header}>
                                </object>
                            </Image>
                        </Link>
                    </Grid.Row>
                </Grid>

                <Divider hidden />

                <MailChimp/>

                <Divider hidden />

            </Container>
        );
    }
}


export default WorkInProgress;
