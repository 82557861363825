/**
 *
 *  Powered by nhobio Development Team ©
 *
 */


import {Link}                                           from "react-router-dom";
import React, { Component }                             from 'react';
import {Button, Dimmer, Divider, Icon, Loader, Message} from "semantic-ui-react";
import styled                                           from "styled-components";
import WaveSurfer                                       from 'wavesurfer.js';

import nhob                                             from '../assets/nhobs/nhob-0001-cybersecurity-consultant.mp3';


const WaveformContianer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;  width: 100%;
  background: transparent;
`;

const Wave = styled.div`
  width: 100%;
  height: 100%;
`;

class AudioPlayer extends Component {
    state = {
        playing: false,
        loaded: false,
    };

    componentDidMount() {
        const track = document.querySelector('#track');

        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: '#waveform',
            backend: 'WebAudio',
            height: 100,
            progressColor: '#8D5CF7',
            responsive: true,
            waveColor: '#EFEFEF',
            cursorColor: 'transparent',
            barHeight: 10,
            barRadius: 4,
        });

        this.waveform.load(track);
        this.waveform.on('ready', () => { this.setState({ loaded: true }); });
    };

    handlePlay = () => {
        if (!this.state.loaded)
            return;
        this.setState({ playing: !this.state.playing });
        this.waveform.playPause();
    };

    render() {

        let icon_name = this.state.playing ? 'pause' : 'play';
        icon_name = this.state.loaded ? icon_name : 'spinner';
        const text = this.state.playing ? 'Pause' : 'Play';

        return (
            <>
                <Divider hidden />
                <WaveformContianer>
                    <Wave id="waveform" />
                    <audio id="track" src={nhob} />
                </WaveformContianer>
                <Divider hidden />
                <Divider hidden />
                { this.state.loaded ? null : <Dimmer active><Loader /></Dimmer> }
                <Message info>
                    <Message.Header><Icon name={"lightbulb outline"}/> N&apos;oublie pas de désactiver le mode silencieux de ton téléphone</Message.Header>
                </Message>
                <Button size={'big'} color={"violet"} icon labelPosition='left' onClick={this.handlePlay}>
                    <Icon name={icon_name} />
                    {text}
                </Button>
                <Link to={'/form/fb'}>
                    <Button size={'big'} color={"green"} icon labelPosition='right'>
                        <Icon name={"arrow right"} />
                        Next
                    </Button>
                </Link>
            </>
        );
    }
}


export default AudioPlayer;
