/**
 *
 *  Powered by Nhobio Development Team ©
 *
 */


import  autoBind                    from "auto-bind";
import  React, { Component }        from 'react';
import  {
    Container,
    Grid,
}                                   from 'semantic-ui-react';


class MailChimp extends Component {


    constructor(props) {
        super(props);
        this.state = {
            emailValue: '',
            fNameValue: '',
            lNameValue: '',
        };
        autoBind(this);
    }


    render() {

        return (
            <Container textAlign='center'>

                <Grid>
                    <Grid.Row centered>
                        <Grid.Column width={10}>

                            <form className={'ui form'} action="https://nhob.us17.list-manage.com/subscribe/post" method="POST" noValidate>

                                <input type="hidden" name="u" value="7971be876880eb32b578943ee"/>
                                <input type="hidden" name="id" value="7f7adf00ad"/>

                                <Grid>
                                    <Grid.Row centered verticalAlign={'middle'}>
                                        <div className="fields">

                                            <div className={'field'}>
                                                <label htmlFor='MERGE1'>
                                            Prénom
                                                    <div className="ui large input"><input
                                                        type="text"
                                                        name="FNAME"
                                                        id="MERGE1"
                                                        value={this.state.fNameValue}
                                                        placeholder={'(optionnel)'}
                                                        onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                                                    /></div>
                                                </label>
                                            </div>

                                            <div className={'field'}>
                                                <label htmlFor='MERGE2'>
                                            Nom
                                                    <div className="ui large input"><input
                                                        type="text"
                                                        name="LNAME"
                                                        id="MERGE2"
                                                        value={this.state.lNameValue}
                                                        placeholder={'(optionnel)'}
                                                        onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
                                                    /></div>
                                                </label>
                                            </div>

                                            <div className={'field'}>
                                                <label htmlFor='MERGE0'>
                                            Email
                                                    <div className="ui large input"><input
                                                        type="email"
                                                        name="EMAIL"
                                                        id="MERGE0"
                                                        value={this.state.emailValue}
                                                        onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
                                                        autoCapitalize="off"
                                                        autoCorrect="off"
                                                    /></div>
                                                </label>
                                            </div>

                                        </div>
                                    </Grid.Row>
                                </Grid>

                                <Grid>
                                    <Grid.Row centered>
                                        <input
                                            type="submit"
                                            value="Je souhaite savoir quand c'est prêt !"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            className="ui violet big button"
                                        />
                                    </Grid.Row>
                                </Grid>
                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                                    <label htmlFor="b_name">Name: </label>
                                    <input type="text" name="b_name" tabIndex="-1" value="" placeholder="Freddie" id="b_name" readOnly/>

                                    <label htmlFor="b_email">Email: </label>
                                    <input type="email" name="b_email" tabIndex="-1" value="" placeholder="youremail@gmail.com" id="b_email" readOnly/>

                                    <label htmlFor="b_comment">Comment: </label>
                                    <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                                </div>
                            </form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}


export default MailChimp;
