/**
 *
 *  Powered by Nhobio Development Team ©
 *
 */


import 'semantic-ui-less/semantic.less';

import './index.css';

import ReactDOM           from 'react-dom';
import React              from 'react';

import App                from './App';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
